<template>
  <nav
    class="pagination"
    aria-label="pagination">
    <paginate
      ref="paginate"
      v-model="page"
      :page-count="pageCount"
      :page-range="pageRange"
      :prev-link-class="color"
      :page-link-class="color"
      :next-link-class="color"
      :prev-text="previousLabel"
      :next-text="nextLabel"
      no-li-surround
      hide-prev-next
      :click-handler="clickCallback"
    >
    </paginate>
  </nav>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import Paginate from 'vuejs-paginate-next'
  export default defineComponent({
    name: 'pagination',
    components: {
      Paginate
    },
    props: {
      pageCount: {
        type: Number,
        required: true
      },
      pageRange: {
        type: Number,
        default: 28
      },
      color: {
        type: String,
        default: 'moon'
      },
      modelValue: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        nextLabel: 'Next',
        previousLabel: 'Prev'
      }
    },
    computed: {
      page: {
        get(): number {
          return this.modelValue
        },
        set(newVal: number) {
          this.$emit('update:modelValue', newVal)
          this.$nextTick(() => {
            this.setAriaLabels()
          })
        }
      }
    },
    methods: {
      isSpanish(): boolean {
        return this.$route.params.lang === 'es'
      },
      clickCallback(page) {
        this.$emit('clicked', page)
      },
      setAriaLabels(): void {
        const pageLinks: HTMLLinkElement[] = this.$el.querySelectorAll('a')
        Array.from(pageLinks).map((link: HTMLLinkElement): void => {
          const themeColor = (this as any).globalTheme.button.backgroundColor
          link.style.color = themeColor
          const linkText: string = link.innerHTML
          const isNumberStep = !isNaN(parseInt(linkText))
          if (isNumberStep) {
            link.setAttribute('role','navigation')
            link.setAttribute(
              'aria-label',
              (this as any).isSpanish()
                ? `
Ir a la página ${linkText}`
                : `Go to page ${linkText}`
            )
          } else {
            link.setAttribute('role','navigation')
            link.setAttribute(
              'aria-label',
              (this as any).isSpanish()
                ? `
ir a la página ${linkText}`
                : `Go to ${linkText} page`
            )
          }
        })
      }
    },
    mounted() {
      this.setAriaLabels()
    }
  })
</script>
