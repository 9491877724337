<template>
  <localized-router-link
    v-if="button.title && button.href"
    :to="button.filePath ? button.filePath : button.href"
    class="btn"
    :class="{ 'auto-break': autoLineBreak }"
    :style="globalTheme.button"
  >
    <slot v-if="hasDefaultSlot"></slot>
    <template v-else>{{button.title}}</template>
  </localized-router-link>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ButtonLink } from '@/types'
  export default defineComponent({
    name: 'buttonLink',
    props: {
      button: {
        required: true,
        type: Object as ()=> ButtonLink
      },
      autoLineBreak: {
        required: false,
        type: Boolean as () => boolean,
        default: false as boolean
      }
    },
    computed: {
      hasDefaultSlot(): boolean {
        return !!this.$slots.default
      }
    }
  })
</script>

<style lang="scss" scoped>
  .auto-break {
    white-space: pre-line;
  }
</style>
