<template>
  <div
    id="mobile-main-nav"
    ref="mobileNav"
    class="mobile">
    <div class="header">
      <logo
        class="height-100" />
      <button
        id="mobile-search-toggle"
        class="toggle">
        <search-icon
          alt="search icon"
          class="search-mobile-menu-icon"
          :style="globalTheme.colorSecondary"/>
      </button>
      <button
        id="menu-toggle"
        class="simple">
        <bars-icon
          id="menu-icon"
          class="bars-mobile-menu-icon"
          alt="menu toggle"
          :style="globalTheme.colorSecondary"/>
      </button>
    </div>
    <div
      id="mobile-search-bar"
      class="search-wrap">
      <search  :uid="'search_mobile'"/>
      <close-icon :style="globalTheme.colorSecondary"/>
    </div>
    <nav aria-label="main">
      <ul
        id="main-accordian-menu"
        class="cd-accordion-menu">
        <template v-for="(item, i) in navItems">
          <li
            v-if="item.secondTier && item.secondTier.length"
            :key="`${i}_category`"
            :style="`order:${(item.mobileOrder + 1) * 2};`"
            class="has-children tier-1"
          >
            <input
              :id="item.group"
              :name="`group-${item.group}`"
              type="checkbox"
            />
            <label
              :for="item.group"
            >{{ item.label }}
              <caret-right />
            </label>
            <ul>
              <li
                v-for="(item, i) in item.secondTier"
                :key="i"
                class="has-children tier-2"
                :style="globalTheme.backgroundColor"
              >
                <a 
                  v-if="item.externalUrl"
                  :href="item.externalUrl"
                  target="_blank"
                  class="sub-nav-item">
                  {{item.label}}
                </a>
                <localized-router-link
                  v-else
                  class="sub-nav-item"
                  :to="item.url"
                  tag="a"
                  :protected="item.protected"
                >
                  {{ item.label }}
                </localized-router-link>
              </li>
            </ul>
          </li>
          <li 
            v-else-if="item.externalUrl"
            :key="`${i}_external`"
            :style="`order:${(item.mobileOrder + 1) * 2};`">
            <a
              class="tier-1"
              :href="item.externalUrl"
              target="_blank">
              <label>
                {{item.label}}
              </label>
            </a>
          </li>
          <li 
            v-else
            :key="`${i}_local`"
            :style="`order:${(item.mobileOrder + 1) * 2};`" >
            <localized-router-link
              class="tier-1"
              :to="item.url"
              :protected="item.protected"
            >
              <label>{{ item.label }}</label>
            </localized-router-link>
          </li>
        </template>
      </ul>
      <language-selector class="_pl0 language-selector mobile"/>
    </nav>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import mobileNav from '@molecules/Navigation/mobile-navigation'
  import LanguageSelector from '@molecules/LanguageSelector.vue'
  import CaretRight from '@atoms/svg/CaretRight.vue'
  import CloseIcon from '@atoms/svg/CloseIcon.vue'
  import Logo from '@atoms/Logo.vue'
  import iconMenu from '@/assets/images/svg/icon-ui-menu.svg'
  import SearchIcon from '@/components/_global/Svg/SearchIcon.vue'
  import BarsIcon from '@/components/_global/Svg/BarsIcon.vue'
  import Search from './Search.vue'

  export default defineComponent({
    name: 'mobileNav' as string,
    components: {
      CloseIcon,
      CaretRight,
      Logo,
      LanguageSelector,
      SearchIcon,
      BarsIcon,
      Search
    },
    props: {
      navItems: {
        type: Array,
        required: true
      },
      colorOverride: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        iconMenu: iconMenu as string
      }
    },
    watch: {
      $route() {
        mobileNav(this.$refs.mobileNav as HTMLElement).reset()
      }
    },
    mounted() {
      mobileNav(this.$refs.mobileNav as HTMLElement).init()
    }
  })
</script>
<style scoped>
.language-selector,
label {
  margin-left: 1rem;
}
label {
  width: 100%;
}
.language-selector{
  margin-left: .5rem;
}
</style>
