<template>
  <section
    class="member-demo-module content-call-to-action" 
    :class="{ 'reverse-order': section.mirrorLayout }">
    <div
      container
      class="content">
      <div grid="row wrap center justify-center">
        <div
          column="xs-12 s-12 l-6"
          class="media-column">
          <div class="featured-media">
            <VideoBlock
              v-if="hasVideo"
              :video="section.featuredVideo"
              :poster="section.featuredVideoPoster"
              :description="section.featuredVideoDescriptionTrack"
              :subtitles="section.featuredVideoSubtitleTrack"
              :embed="section.featuredEmbedUrl"
            />
            <img
              v-else-if="section.featuredVideoPoster"
              :src="section.featuredVideoPoster.url"
              :alt="section.featuredImageAlt"
            />
          </div>
        </div>
        <div
          v-if="section.title || section.content"
          column="xs-12 s-12 l-6"
        >
          <div class="read-content">
            <div>
              <h2
                v-if="section.title"
                :style="globalTheme.colorWhite"
                class="h2 _mt0"
              >
                {{ section.title }}
              </h2>
              <div v-if="section.content">
                <rich-text :html="section.content"></rich-text>
              </div>
            </div>
            <div
              v-if="section.buttonLink && section.buttonLink.href"
              class="cta-wrap"
            >
              <app-button-link
                :button="section.buttonLink"
                :style="globalTheme.buttonWhite"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="themed-background"
      :style="globalTheme.backgroundColor"/>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, ContentStackFile } from '@/types'
  import VideoBlock from '@/components/sections/ContentWithVideo/VideoBlock.vue'

  interface MediaFeature extends Boilerplate {
    mirrorLayout: boolean,
    featuredVideo?: ContentStackFile;
    featuredEmbedUrl: {
      href?: string;
      title?: string;
    };
  }

  export default defineComponent({
    name: 'content-call-to-action',
    components: {
      VideoBlock
    },
    props: {
      section: {
        required: true,
        type: Object as () => MediaFeature
      }
    },
    computed: {
      hasVideo(): boolean {
        return (!!this.section.featuredVideo || !!(this.section.featuredEmbedUrl && this.section.featuredEmbedUrl.href))
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
