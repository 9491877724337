export function globalFieldsFactory(section): Record<string, unknown> {
  const colors = section.colors || section.colorOptions
  if (section.contentTypeUid !== 'nav_anchor' && section.contentTypeUid !== 'nav_anchor_bookend') {
    section.title = section.sectionTitle
  }
  return {
    ...section,
    colorOptions: {
      backgroundAsOffwhite: colors ? colors.backgroundAsOffwhite : false,
      useSecondaryColor: colors ? colors.useSecondaryColor : false,
      colorOverride: colors ? colors.colorOverride : null
    }
  }
}
