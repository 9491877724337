<template>
  <template v-if="!page && loaded">
    <four-o-four />
  </template>
  <template v-else-if="loaded">
    <div id="app-body">
      <post-body
        v-if="!postContentPlacedByLoop"
        :section="postBodyContent"
      />
      <the-loop
        v-if="page && page.loopableSections.length"
        :loaded="loaded"
        :loopable-sections="page.loopableSections"
      />
      <div
        container
        class="hide-print _text-center  _mbm"
        role="region" 
        aria-label="Article Tools Section">
        <button
          class="btn btn-svg --sm"
          :aria-label="`${printPageLabel} button for ${postBodyContent.title} Article`"
          :style="globalTheme.buttonSecondaryWhiteBordered"
          @click="printPage">
          <app-inline-svg
            class="_mrxxs btn-svg"
            :src="printIcon"
            :style="globalTheme.colorSecondary"/>
          {{printPageLabel}}
        </button>
      </div>
    </div>
  </template>
  <app-loader v-else />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TheLoop from '@/components/TheLoop.vue'
  import FourOFour from '@/views/404.vue'
  import PostBody from '@/components/sections/PostBody.vue'
  import { PostBodyProps } from '@/components/sections/PostBody.vue'

  import { ContentStackPagePayload } from '@/types'

  import { getPage } from '@/ts/contentstack'
  import { AdobeAnalytics } from '@/mixins/AdobeAnalytics'

  import { includeReferenceList } from '@/ts/contentstack'

  import printIcon from '@/assets/images/svg/print-leaf.svg'

  export default defineComponent({
    name: 'Post',
    components: {
      FourOFour,
      TheLoop,
      PostBody
    },
    mixins: [ AdobeAnalytics ],
    data() {
      return {
        page: null as Record<string, any> | null,
        loaded: false,
        printIcon: printIcon,
        printPageLabel: this.$store.state.globals.globalLabels.printPage || 'Print Page'
      }
    },
    computed: {
      postBodyContent(): PostBodyProps {
        return {
          title: this.page ? this.page.title : '',
          content: this.page ? this.page.fullDescription : '',
          featuredImage: this.page ? this.page.featuredImage : null,
          sidebar: this.page ? this.page.sidebar : null,
          accordionDrawers: this.page ? this.page.accordionDrawers : [],
          buttonLink: this.page ? this.page.buttonLink : null,
          colorOptions: {},
          featuredImageAlt: this.page ? this.page.featuredImageAlt : null
        }
      },
      postContentPlacedByLoop(): boolean {
        if (!this.page) return false
        return !!this.page.loopableSections.find(
          section => section.contentTypeUid === 'place_post_content'
        )
      }
    },
    methods: {
      printPage(): void {
        window.print()
      },
      setContentStackPayload(): ContentStackPagePayload {
        const currentLang = this.$route.params.lang as string
        const currentClientID = this.$route.params.clientID as string
        const pageSlug =
          `${
            this.$route.fullPath
              .replace(`/${this.$route.params.clientID}`, '')
              .replace(`/${this.$route.params.lang}`, '')
              .replace('/post', '')
              .split('?')[0]
          }` || ('/' as string)

        return {
          pageType: 'posts',
          lang: currentLang,
          clientID: currentClientID,
          pageSlug: pageSlug,
          uid: '',
          includeReferenceList: [
            'category',
            'category.microsite_references.omit_from',
            'category.microsite_references.appear_only',
            'sidebar.theme_color_override',
            'sidebar.related_posts',
            'sidebar.related_posts.microsite_references.omit_from',
            'sidebar.related_posts.microsite_references.appear_only',
            ...includeReferenceList
          ]
        }
      }
    },
    async created() {
      const stackPayload = this.setContentStackPayload()
      const globals = this.$store.state.globals
      const globalsUID = globals.uid
      stackPayload.uid = globalsUID
      const page = await getPage(stackPayload)
      this.page = page

      if (page) {
        page.loopableSections = page.loopableSections.map(section => {
          if (section.contentTypeUid === 'place_post_content') {
            section = { ...section, ...this.postBodyContent, colorOptions: section.colorOptions }
          }
          return section
        })
      }

      this.loaded = true
      if (page) {
        this.$store.commit('setCurrentRoute', this.$route.path)
        this.$store.commit('setCurrentPage', page)
        this.sendItToAdobe(page.title)
      }
    }
  })
</script>
