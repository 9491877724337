import { camelCase, forEach, isArray, isPlainObject } from 'lodash'

export function isTestingEnvironment(): boolean {
  return (
    import.meta.env.NODE_ENV !== 'production' ||
    window.location.host === 'memberuat.eyemedvisioncare.com'
  )
}

export function objectKeysToCamelCase(snake_case_object): any {
  const camelCaseObject = {}
  forEach(snake_case_object, function(value, key) {
    if (isPlainObject(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      value = objectKeysToCamelCase(value) // recursively update keys of any values that are also objects
    }
    if (isArray(value)) {
      value = value.map(v => {
        if (isPlainObject(v)) {
          return objectKeysToCamelCase(v) // recursion again on objects in arrays
        } else {
          return v
        }
      })
    }
    camelCaseObject[camelCase(key)] = value
  })
  return camelCaseObject
}

export function prepareMockedData(data): any {
  if (Array.isArray(data)) {
    return data.map(d => {
      return objectKeysToCamelCase(JSON.parse(JSON.stringify(d)))
    })
  } else {
    return objectKeysToCamelCase(JSON.parse(JSON.stringify(data)))
  }
}

export function getPercentageOfAmount(
  amount: number,
  fullAmount: number
): string {
  return ((amount / fullAmount) * 100).toFixed(0)
}

export function sectionCheck(sectionsArray, sectionName: string) {
  return sectionsArray && sectionsArray[sectionName]
    ? sectionsArray[sectionName][0]
    : undefined
}

export function hasLength(
  someString: string | undefined | null
): string | undefined {
  return someString && someString.length ? someString : undefined
}

export function isEquivalent(a, b) {
  // check object equivalency
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}

export function isIE(): boolean {
  return (window.document as any).documentMode !== undefined
}

export function isAbsoluteUrl(url: string): boolean {
  const httpCheck = new RegExp('^(http|https)://', 'i')
  const wwwCheck = new RegExp('^www', 'i')
  return httpCheck.test(url) || wwwCheck.test(url)
}

export function getParameterByName(name, url): string | null {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function setPostLinkCardBlurb(post: ContentStackPost): string {
  return post.fullDescription ? stripHtml(post.fullDescription) : extractPostBlurbFromLoop(post)
}

export function stripHtml(html) {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
import { ContentStackPost } from '@/types'

export function extractPostBlurbFromLoop(post: ContentStackPost): string {
  if (post.sections && post.sections.length) {
    const firstRichTextBlock = post.sections.find(
      section => section.contentTypeUid === 'simple_rich_text'
    )
    if (firstRichTextBlock) {
      return stripHtml(firstRichTextBlock.content)
    }
  }
  return ''
}
