import { camelCase } from 'change-case'
import factorySelector from '@/ts/factory-selector'
import { globalFieldsFactory } from '@/ts/factories/global-fields.factory'
import { NavItem } from '@/types'

export function createLoopableSections(sections): any[] {
  if (!sections) return []
  const orderedSections = [] as any[]
  sections.map(function(section, i) {
    setAriaLabel(section)
    const name = camelCase(section.contentTypeUid)
    const factory = factorySelector(name)
    if (!factory) return
    const modeledData = (() => {
      const originalSection = globalFieldsFactory(section)
      const formattedSection = factory(section)
      return { ...originalSection, ...formattedSection }
    })()
    orderedSections.push({
      ...modeledData,
      order: i + 1 * 2,
      componentName: name
    })
  })
  return orderedSections.map((module, index, elements) => {
    const nextModule = elements[index+1]
    if (
      module.contentTypeUid === 'nav_anchor' && nextModule) {
      nextModule.navAnchorData = { label: module.navItemLabel, uid: module.title } as NavItem
    }
    return module
  })
  .filter(module => module.contentTypeUid !== 'nav_anchor')
  .filter(module => !!module.publishDetails)
}


export function setAriaLabel(section: Record<string, any> ): void {
  const ariaLabel = section.ariaLabel as {ariaLabel: string} | null
  if (ariaLabel) {
    section.ariaLabel = ariaLabel.ariaLabel
  }
}
