<template>
  <template v-if="loaded">
    <section class="member-demo-module">
      <pagination
        v-if="totalPages > 1"
        :key="`above_${currentPage}`"
        v-model="currentPage"
        aria-label="posts pagination"
        class="_mbs"
        :page-count="totalPages"
        :page-range="pageRange"
      />
      <app-loader v-if="reFetching" />
      <post-cards
        v-else
        :section="section"
        :posts="allPosts" />
      <pagination
        v-if="totalPages > 1"
        :key="`below_${currentPage}`"
        v-model="currentPage"
        aria-label="posts pagination"
        class="_mts"
        :page-count="totalPages"
        :page-range="pageRange"
      />
    </section>
  </template>
  <app-loader v-else />
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import PostCards from '@/components/sections/FeaturedPosts/PostCards.vue'
  import Pagination from '@/components/molecules/Pagination.vue'

  import { ContentStackEntryPayload, Boilerplate } from '@/types'
  import { getPostsByCategory } from '@/ts/contentstack'

  export default defineComponent({
    name: 'AllPosts',
    components: {
      PostCards,
      Pagination
    },
    props: {
      section: {
        type: Object as () => Boilerplate,
        required: false
      },
      pageRange: {
        type: Number,
        default: 27
      },
      categoryUrl: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        allPosts: [] as Record<string, any>[],
        count: 0,
        currentPage: 1,
        loaded: false,
        reFetching: false,
        postsPayload: {
          clientID: this.$route.params.clientID as string,
          contentTypeUid: 'posts',
          lang: this.$route.params.lang as string,
          referenceFieldPath: [
            'category',
            'microsite_references.omit_from',
            'microsite_references.appear_only'
          ],
          jsonRtePath: ['full_description'],
          skip: 0,
          limit: this.pageRange,
          categoryUrl: this.categoryUrl
        } as ContentStackEntryPayload
      }
    },
    computed: {
      totalPages(): number | undefined {
        return Math.ceil(this.count / this.pageRange)
      }
    },
    watch: {
      currentPage(newPage) {
        this.getPostsByPage(newPage)
      }
    },
    methods: {
      async getPostsByPage(currentPage) {
        const postsPayload = this.postsPayload
        postsPayload.skip = (currentPage - 1) * this.pageRange
        this.reFetching = true
        const allPosts = await getPostsByCategory(postsPayload)
        if (allPosts) {
          this.allPosts = allPosts.posts
          this.count = allPosts.count
        }
        this.reFetching = false
      }
    },
    async created() {
      await this.getPostsByPage(1)
      this.loaded = true
    },
    provide() {
      return {
        colorOptions: this.section ? this.section.colorOptions : null
      }
    }
  })
</script>
