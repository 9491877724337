<template>
  <post-body
    :section="section"/>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import PostBody from '@/components/sections/PostBody.vue'
  import { PostBodyProps } from '@/components/sections/PostBody.vue'
  export default defineComponent({
    name: 'place-post-content',
    components: { PostBody },
    props: {
      section: {
        required: true,
        type: Object as () => PostBodyProps
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
