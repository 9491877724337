<template>
  <section 
    v-if="!preventFetchByCat"
    class="member-demo-module spotlight-features featured-post-links">
    <div container>

      <div
        v-if="section.title || showCategorySelect"
        grid="row wrap"
        class="intro-content _mbs">

        <div :column="showCategorySelect && !postsExist ? 'xs-12 s-12 m-9' : 'xs-12 s-12'">
          <h2
            v-if="section.title"
            :style="globalTheme.colorText"
            class="intro-title">
            {{ section.title }}
            <app-inline-svg
              v-if="section.titleSvgIcon"
              class="section-title-svg _mlxs"
              :style="globalTheme.fillText"
              :src="section.titleSvgIcon.url" />
          </h2>

        </div>

        <div
          v-if="showCategorySelect"
          column="xs-12 s-12"
          class="mobile until-tablet">
          <category-selector
            :current-category="referencedCategory"
            @newCategorySelected="updateSelectedCategory"/>  
        </div>

        <div
          v-if="showCategorySelect && !postsExist"
          column="xs-12 s-12 m-3">
          <category-selector
            :current-category="referencedCategory"
            @newCategorySelected="updateSelectedCategory"/>
        </div>

      </div>
      <app-loader v-if="fetching"/>
      <p
        v-else-if="showCategorySelect && !postsExist"
        class="_mtl _text-center"> 
        {{noPostsMessage}} 
      </p>
      <div
        v-else
        grid="row wrap justify-center">
        <div
          v-if="firstFeature"
          column="xs-12 s-12 m-8"
          class="">
          <spotlight-feature
            :post="firstFeature"
            first />
        </div>
        <div
          v-if="spotlightFeaturesSide.length"
          column="xs-12 s-12 m-4"
          class="">
          <div
            grid="row wrap"
            class="side-features">
            <div
              v-if="showCategorySelect"
              column="xs-12 s-12 m-12"
              class="_mbs tablet-up">
              <category-selector
                :current-category="referencedCategory"
                @newCategorySelected="updateSelectedCategory"/>
            </div>
            <div
              v-for="(post, i) in spotlightFeaturesSide"
              :key="i"
              column="xs-12 s-12 m-12"
            >
              <spotlight-feature :post="post" />
            </div>
            <div
              v-if="!spotlightFeaturesRest.length"
              column="xs-12 s-12"
              class="_text-center"
            >
              <localized-router-link
                v-if="section.seeMoreCta && section.seeMoreCta.title && section.seeMoreCta.href"
                class="see-more-cta"
                :to="section.seeMoreCta.href"
                :style="globalTheme.colorText"
              >
                {{ section.seeMoreCta.title }}
              </localized-router-link>
            </div>
          </div>
        </div>
        <template v-if="spotlightFeaturesRest.length">
          <div
            v-for="(post, i) in spotlightFeaturesRest"
            :key="i"
            column="xs-12 s-12 m-4"
            class=""
          >
            <spotlight-feature :post="post" />
          </div>
          <div
            column="xs-12 s-12 m-4"
            class="_text-center">
            <localized-router-link
              v-if="section.seeMoreCta && section.seeMoreCta.title && section.seeMoreCta.href"
              class="see-more-cta"
              :to="section.seeMoreCta.href"
              :style="globalTheme.colorText"
            >
              {{ section.seeMoreCta.title }}
            </localized-router-link>
          </div>
        </template>
        <div
          v-if="section.seeMoreCta && 
            section.seeMoreCta.title &&
            section.seeMoreCta.href &&
            !spotlightFeaturesRest.length &&
            !spotlightFeaturesSide.length
          "
          column="xs-12 s-12 m-6"
          class="_text-center"
        >
          <localized-router-link
            class="see-more-cta"
            :to="section.seeMoreCta.href"
            :style="globalTheme.colorText"
          >
            {{ section.seeMoreCta.title }}
          </localized-router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import cloneDeep from 'lodash.clonedeep';

  import { defineComponent } from 'vue'
  import { ContentStackPost } from '@/types'

  import {
    postsState,
    FeaturedPostsSection,
    SiftedPosts
  } from '@/mixins/FeaturedPostsModuleState'

  import {
    SpotlightFeatureProps,
    spotlightFeatureFactory
  } from '@/components/sections/FeaturedPosts/SpotlightFeature.vue'

  import SpotlightFeature from '@/components/sections/FeaturedPosts/SpotlightFeature.vue'

  interface SpotlightFeatureSections extends FeaturedPostsSection {
    manualEntries: SpotlightFeatureProps[];
  }

  export default defineComponent({
    name: 'SpotlightFeatures' as string,
    components: { SpotlightFeature },
    mixins: [postsState],
    props: {
      section: {
        type: Object as () => SpotlightFeatureSections,
        required: true
      }
    },
    data() {
      return {
        readMoreLabel: this.$store.state.globals.globalLabels.readMore
      }
    },
    computed: {
      firstFeature(): SpotlightFeatureProps | undefined {
        const postsToDisplay = (this as any).postsToDisplay as SiftedPosts
        let allPosts
        if (postsToDisplay.referencedPostList) {
          allPosts = (postsToDisplay.posts as ContentStackPost[]).map(refPost =>
            spotlightFeatureFactory(refPost, this.readMoreLabel)
          )
        } else {
          allPosts = postsToDisplay.posts as SpotlightFeatureProps[]
        }
        const firstFeature = allPosts[0]
        if (firstFeature) {
          firstFeature.blurb = this.readMoreLabel
        }
        return firstFeature
      },
      spotlightFeaturesSide(): SpotlightFeatureProps[] {
        const allPosts = (this as any).postsToDisplay.posts
        const referencedPostList = (this as any).postsToDisplay.referencedPostList
        if (allPosts <= 1) return []
        const postsToDisplay = cloneDeep(allPosts)
        if (referencedPostList) {
          return (postsToDisplay as ContentStackPost[])
            .map(refPost => spotlightFeatureFactory(refPost, this.readMoreLabel))
            .slice(1, 3)
        } else {
          return postsToDisplay.slice(1, 3) as SpotlightFeatureProps[]
        }
      },
      spotlightFeaturesRest(): SpotlightFeatureProps[] {
        const allPosts = (this as any).postsToDisplay.posts
        const referencedPostList = (this as any).postsToDisplay.referencedPostList
        if (allPosts <= 3) return []
        const postsToDisplay = cloneDeep(allPosts)
        if (referencedPostList) {
          return postsToDisplay.map(refPost => spotlightFeatureFactory(refPost, this.readMoreLabel)).slice(3, 99) as SpotlightFeatureProps[]
        } else {
          return postsToDisplay.slice(3, 99) as SpotlightFeatureProps[]
        }
      }
    }
  })
</script>
