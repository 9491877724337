import Resources from '@/components/sections/Resources.vue'
import SimpleRichText from '@/components/sections/SimpleRichText.vue'
import SmallCalloutsWithImage from '@/components/sections/SmallCalloutsWithImage.vue'
import ConstituentAction from '@/components/sections/ConstituentAction.vue'
import SmallFeature from '@/components/sections/SmallFeature.vue'
import SmallFeatureSingle from '@/components/sections/SmallFeatureSingle.vue'
import SimpleTitle from '@/components/sections/SimpleTitle.vue'
import FeaturedLists from '@/components/sections/FeaturedLists.vue'
import IconWithText from '@/components/sections/IconWithText.vue'
import ChoiceModals from '@/components/sections/ChoiceModals.vue'
import PlacePosts from '@/components/sections/PlacePosts.vue'
import AllPosts from '@/components/sections/AllPosts.vue'
import PlacePostContent from '@/components/sections/PlacePostContent.vue'
import HeroWithContent from '@/components/sections/HeroWithContent.vue'
import ContentWithVideo from '@/components/sections/ContentWithVideo.vue'
import Chart from '@/components/sections/Chart.vue'
import ComboContentFeature from '@/components/sections/ComboContentFeature.vue'
import IconBulletSection from '@/components/sections/IconBulletSection.vue'
import ContentCallToAction from '@/components/sections/ContentCallToAction.vue'
import Glossary from '@/components/sections/Glossary.vue'
import IframeContent from '@/components/sections/IframeContent.vue'
import SearchGlossary from '@/components/sections/SearchGlossary.vue'

// featured post sections
import FeaturedCards from '@/components/sections/FeaturedCards.vue'
import MixedFeatures from '@/components/sections/MixedFeatures.vue'
import ArticleCards from '@/components/sections/ArticleCards.vue'
import SpotlightFeatures from '@/components/sections/SpotlightFeatures.vue'
import CuratedFeatures from '@/components/sections/CuratedFeatures.vue'
import LargeCards from '@/components/sections/LargeCards.vue'
import ArchiveCards from '@/components/sections/ArchiveCards.vue'
import CategoryCards from '@/components/sections/CategoryCards.vue'
import MiniCalloutCards from '@/components/sections/MiniCalloutCards.vue'

export default {
  Resources,
  SmallCalloutsWithImage,
  SimpleRichText,
  ConstituentAction,
  SmallFeature,
  SmallFeatureSingle,
  SimpleTitle,
  FeaturedLists,
  IconWithText,
  ChoiceModals,
  PlacePosts,
  AllPosts,
  PlacePostContent,
  HeroWithContent,
  FeaturedCards,
  MixedFeatures,
  ArticleCards,
  SpotlightFeatures,
  CuratedFeatures,
  LargeCards,
  ArchiveCards,
  CategoryCards,
  MiniCalloutCards,
  ContentWithVideo,
  Chart,
  ComboContentFeature,
  IconBulletSection,
  ContentCallToAction,
  Glossary,
  IframeContent,
  SearchGlossary
}
