<template>
  <div
    :class="{ collapsed: sectionCollapsed && collapsible }"
    class="collapsible-section"
    :style="{backgroundColor: backgroundAsOffwhite ? '#fff' : '#F8F8F8'}"
  >
    <button
      v-if="!hideHeader"
      class="bg-white section-header simple drawer-toggle"
      :style="globalTheme.color"
      :aria-controls="uid"
      :aria-expanded="expanded"
      @click.prevent="sectionCollapseToggled"
    >
      <div container>
        <div
          v-if="hasTitleSlot"
          :id="`${uid}_title`"
          class="sr-only"><slot name="header-text"></slot></div>
        <slot name="header-text"></slot>
        <slot name="header-content"></slot>

        <div class="actions">
          <slot
            :collapsed="sectionCollapsed"
            name="actions-left"></slot>
          <p
            v-if="collapsible && !hideToggle"
            class="collapse-label"
            :style="globalTheme.color"
          >
            {{toggleLabel}}
          </p>
          <div
            v-if="collapsible"
            :class="collapseColorClass"
            class="collapse-button btn"
            :style="sectionCollapsed ? globalTheme.button : globalTheme.buttonWhiteBordered">
            <font-awesome-icon
              aria-hidden="true"
              :icon="collapseIconName" />
          </div>
        </div>
      </div>
    </button>
    <transition
      @enter="enter"
      @leave="leave">
      <div
        v-show="!sectionCollapsed && collapsible"
        :id="uid"
        role="region"
        :aria-label="contentAriaLabel"
        class="drawer">
        <div class="content">
          <slot :collapsed="sectionCollapsed" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import Velocity from 'velocity-animate'
  import { v4 as uuidv4 } from 'uuid'

  export default defineComponent({
    name: 'collapsible-section',
    props: {
      collapsed: {
        type: Boolean,
        default: false
      },
      collapsible: {
        type: Boolean,
        default: true
      },
      hideHeader: {
        type: Boolean,
        default: false
      },
      hideToggle: {
        type: Boolean,
        default: false
      },
      providedUID: {
        type: String,
        required: false
      },
      forceFocus: {
        type: Boolean,
        default: false
      },
      backgroundAsOffwhite: {
        type: Boolean,
        default: false
      },
      contentAriaLabel: {
        type: String,
        defaut: 'faq info text region'
      }
    },
    data() {
      return {
        sectionCollapsed: this.collapsed as boolean,
        uid: this.providedUID ? this.providedUID : `UID_${uuidv4()}`,
        preventFocus: false,
        title: null as string|null
      }
    },
    computed: {
      expanded(): string {
        return (!this.sectionCollapsed).toString()
      },
      collapseIconName(): string {
        return this.sectionCollapsed ? 'plus' : 'minus'
      },
      toggleLabel(): string {
        const close = this.$store.state.globals.globalLabels.close || 'Close'
        const open = this.$store.state.globals.globalLabels.open || 'Open'
        return this.sectionCollapsed ? open : close
      },
      collapseColorClass(): string {
        return this.sectionCollapsed ? 'isOpen' : 'white bordered'
      },
      hasTitleSlot () {
        return !!this.$slots['header-text']
      },
    },
    watch: {
      collapsed(newVal: boolean): void {
        this.sectionCollapsed = newVal
        this.preventFocus = true
      }
    },
    methods: {
      getTitle(): void {
        const titleEl: HTMLElement|null = this.$el.querySelector(`#${this.uid}_title`)
        if (!titleEl) return
        const text = titleEl!.innerText
        this.title = text
      },
      sectionCollapseToggled(): void {
        this.preventFocus = false
        this.sectionCollapsed = !this.sectionCollapsed
        if (this.sectionCollapsed === true) {
          this.$emit('drawerClosed')
        } else {
          this.$emit('drawerOpened')
        }
        this.$emit('clicked')
      },
      focusContents(): void {
        const contentHtmlNode = this.$el.querySelector(`#${this.uid}`) as HTMLElement
        contentHtmlNode.setAttribute('tabindex', '0')
        contentHtmlNode.focus()
        contentHtmlNode.removeAttribute('tabindex')
        if ((contentHtmlNode.clientHeight || contentHtmlNode.offsetHeight || contentHtmlNode.scrollHeight) > window.innerHeight) {
          const crumbEl: HTMLLIElement|null = document.querySelector('#sub-nav-breadcrumb')
          const crumbHeight = crumbEl ? crumbEl.clientHeight || crumbEl.offsetHeight || crumbEl.scrollHeight : 0
          const header: HTMLElement|null = this.$el.querySelector('.section-header')
          const elHeight = header ? header.clientHeight || header.offsetHeight || header.scrollHeight : 0
          const total = crumbHeight+elHeight
          setTimeout(() => {
            window.scrollBy(0,total * -1)
          }, 1)
        }
      },
      enter(el: HTMLElement, done: boolean): void {
        Velocity(
          el,
          'slideDown',
          { duration: 150, easing: 'easeInBack' },
          { complete: done }
        )
          .then(() => {
            if (this.preventFocus && !this.forceFocus) return
            this.focusContents()
          })
      },
      leave(el: HTMLElement, done: boolean): void {
        Velocity(
          el,
          'slideUp',
          { duration: 150, easing: 'easeInBack' },
          { complete: done }
        )
      }
    },
    mounted() {
      this.getTitle()
    }
  })
</script>
