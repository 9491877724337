<template>
  <template v-if="!page && loaded" >
    <four-o-four />
  </template>
  <template v-else-if="loaded">
    <div id="app-body">
      <the-loop
        v-if="page && page.loopableSections.length"
        :loaded="loaded"
        :loopable-sections="page.loopableSections"
      />
    </div>
  </template>
  <app-loader v-else/>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TheLoop from '@/components/TheLoop.vue'
  import FourOFour from '@/views/404.vue'

  import {
    ContentStackPagePayload,
  } from '@/types'

  import { getPage } from '@/ts/contentstack'
  import { AdobeAnalytics } from '@/mixins/AdobeAnalytics'

  import { includeReferenceList } from '@/ts/contentstack'

  export default defineComponent({
    name: 'Page',
    components: {
      FourOFour,
      TheLoop,
    },
    mixins: [ AdobeAnalytics ],
    data() {
      return {
        page: null as Record<string, any> | null,
        loaded: false,
      }
    },
    methods: {
      setContentStackPayload(): ContentStackPagePayload {
        const currentLang = this.$route.params.lang as string
        const currentClientID = this.$route.params.clientID as string
        const pageSlug =
          `${
            this.$route.fullPath
              .replace(`/${this.$route.params.clientID}`, '')
              .replace(`/${this.$route.params.lang}`, '')
              .split('?')[0]
          }` || ('/' as string)

        return {
          pageType: undefined,
          lang: currentLang,
          clientID: currentClientID,
          pageSlug: pageSlug,
          uid: '',
          includeReferenceList: [
            'sections.terms.associated_pages',
            ...includeReferenceList
          ]
        }
      }
    },
    async created() {
      const stackPayload = this.setContentStackPayload()
      const globals = this.$store.state.globals
      const globalsUID = globals.uid
      stackPayload.uid = globalsUID
      const page = await getPage(stackPayload)
      this.page = page
      this.loaded = true
      if (page) {
        this.$store.commit('setCurrentRoute', this.$route.path)
        this.$store.commit('setCurrentPage', page)
        this.sendItToAdobe(page.title)
      }
    }
  })
</script>
