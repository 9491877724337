import { trapFocus } from '@/ts/dom-helper.methods'
import Velocity from 'velocity-animate'

export default function(
  el: HTMLElement | null = document.querySelector('#mobile-main-nav')
): any {
  // main nav toggles
  const mainNavMenu: HTMLElement = el!
  const searchOpen: HTMLElement | null = el!.querySelector(
    '#mobile-search-toggle'
  )
  const searchBar: HTMLElement | null = el!.querySelector('#mobile-search-bar')
  const searchClose: SVGElement | null = searchBar!.querySelector('svg')
  const menuToggle: HTMLImageElement | null = el!.querySelector('#menu-toggle')
  const body: HTMLElement = document.body

  function showMobileElement(element: HTMLElement): void {
    element.classList.add('show-me')
  }

  function hideMobileElement(element: HTMLElement): void {
    element.classList.remove('show-me')
  }

  function toggleMobileElement(element: HTMLElement): void {
    const elClassList = element.classList
    if (elClassList.contains('show-me')) {
      element.classList.remove('show-me')
      body.classList.remove('lock-body')
    } else {
      element.classList.add('show-me')
      body.classList.add('lock-body')
      trapFocus(el as HTMLElement)
    }
  }

  function getSiblings(elem: Element | null): Element[] {
    // Setup siblings array and get the first sibling
    const siblings: Element[] = []
    let sibling: ChildNode | any = elem!.parentNode!.firstChild

    // Loop through each sibling and push to the array
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling)
      }
      sibling = sibling.nextSibling
    }

    return siblings
  }

  //accordion elements
  const accordionsMenu = el!.querySelector('.cd-accordion-menu')
  const dropdowns = accordionsMenu!.querySelectorAll('.has-children.tier-1')

  function reset(): void {
    const element = mainNavMenu
    element.classList.remove('show-me')
    body.classList.remove('lock-body')
    dropdowns.forEach(function(dropdown) {
      const drawer = dropdown.querySelector('ul')
      const toggle: HTMLInputElement | null = dropdown.querySelector(
        'input[type="checkbox"]'
      )
      Velocity(drawer, 'slideUp', { duration: 150, easing: 'easeInBack' })
      toggle!.checked = false
    })
  }

  function init(): void {
    //menu toggles
    searchOpen!.addEventListener('click', () => {
      showMobileElement(searchBar!)
    })

    searchClose!.addEventListener('click', () => {
      hideMobileElement(searchBar!)
    })

    menuToggle!.addEventListener('click', (): void => {
      toggleMobileElement(mainNavMenu!)
    })

    dropdowns.forEach(function(dropdown) {
      const drawer = dropdown.querySelector('ul')
      const toggle = dropdown.querySelector('input[type="checkbox"]')
      const uncles = getSiblings(dropdown).filter(result => {
        return result.classList.contains('has-children')
      })
      const cousins: (HTMLInputElement | null)[] = uncles.map(
        (cousin: Element) => {
          return cousin.querySelector('input[type="checkbox"]')
        }
      )

      function closeCousins() {
        uncles.forEach(dropdown => {
          const drawer = dropdown.querySelector('ul')
          Velocity(drawer, 'slideUp', { duration: 150, easing: 'easeInBack' })
        })
        cousins.forEach(function(cousin: HTMLInputElement | null): any {
          cousin!.checked = false
        })
      }

      toggle!.addEventListener('change', function(this) {
        if (this.checked) {
          Velocity(drawer, 'slideDown', {
            duration: 150,
            easing: 'easeInBack'
          })
          closeCousins()
        } else {
          Velocity(drawer, 'slideUp', { duration: 150, easing: 'easeInBack' })
        }
      })
    })
  }

  return {
    init: init,
    reset: reset
  }
}
