<template>
  <svg
    aria-label="click to dismiss this message"
    style="enable-background:new 0 0 400 400;"
    tabindex="0"
    version="1.1"
    viewBox="0 0 400 400"
    x="0px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <polygon
      fill="currentColor"
      class="stclose"
      points="400,44.1 355.9,0 200,155.9 44.1,0 0,44.1 155.9,200 0,355.9 44.1,400 200,244.1 355.9,400 400,355.9
      244.1,200 "
    ></polygon>
  </svg>
</template>
<style scoped>
  .stclose {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
</style>
