<template>
  <section class="member-demo-module">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="section-header h2"
        >
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          class="copy-wrap">
          <rich-text :html="section.content"></rich-text>
        </div>
      </div>
      <div
        v-if="resources && resources.length"
        grid="row wrap"
        class="_mtm">
        <div
          v-for="(resource, g) in resources"
          :key="g"
          :column="
            section.desktopResourceColumnCount === 'double'
              ? 'xs-12 s-12 m-6'
              : 'xs-12 s-12'
          "
        >
          <resource
            :background-as-offwhite="section.colorOptions.backgroundAsOffwhite"
            :resource="resource"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, Resource as ResourceInterface } from '@/types'
  import Resource from '@/components/sections/Shared/Resource.vue'

  interface Resources extends Boilerplate {
    resources?: ResourceInterface[];
    desktopResourceColumnCount: string;
  }

  export default defineComponent({
    name: 'Resources',
    components: {
      Resource
    },
    props: {
      section: {
        required: true,
        type: Object as () => Resources
      }
    },
    data() {
      return {
        resources: this.section.resources
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
