<template>
  <section class="member-demo-module mini-callout-cards featured-post-links">
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="_mbs intro-title _text-center"
        >
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          grid="row wrap justify-center"
          class="_mbxs">
          <div column="xs-12 s-12 m-10 l-8">
            <div class="copy-wrap _text-center">
              <rich-text :html="section.content"></rich-text>
            </div>
          </div>
        </div>
      </div>
      <div grid="row wrap stretch">
        <div
          v-for="(post, i) in categoryCards"
          :key="i"
          column="xs-12 s-6 m-6 l-4"
          class="_mbs _mts"
        >
          <category-card :post="post" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackPost } from '@/types'

  import {
    postsState,
    FeaturedPostsSection,
    SiftedPosts
  } from '@/mixins/FeaturedPostsModuleState'

  import {
    CategoryCardProps,
    miniCalloutCardFactory
  } from '@/components/sections/FeaturedPosts/CategoryCard.vue'

  import CategoryCard from '@/components/sections/FeaturedPosts/CategoryCard.vue'

  interface CategoryCardsSections extends FeaturedPostsSection {
    manualEntries: CategoryCardProps[];
  }

  export default defineComponent({
    name: 'CategoryCards' as string,
    components: { CategoryCard },
    mixins: [postsState],
    props: {
      section: {
        type: Object as () => CategoryCardsSections,
        required: true
      }
    },
    data() {
      return {
        readMoreLabel: this.$store.state.globals.globalLabels.readMore
      }
    },
    computed: {
      categoryCards(): CategoryCardProps[] {
        const postsToDisplay = (this as any).postsToDisplay as SiftedPosts
        if (postsToDisplay.referencedPostList) {
          return (postsToDisplay.posts as ContentStackPost[]).map(refPost =>
            miniCalloutCardFactory(refPost, this.readMoreLabel)
          )
        } else {
          return postsToDisplay.posts as CategoryCardProps[]
        }
      }
    }
  })
</script>
