<template>
  <div
    v-if="crumbs.length <= 3 || crumbs[3] === '/'"
    id="sub-nav-breadcrumb"
    class="sub-nav-breadcrumb no-crumbs"
    role="navigation"
    :style="globalTheme.backgroundColor"
  ></div>
  <div
    v-else
    id="sub-nav-breadcrumb"
    aria-label="subnav"
    role="navigation"
    class="sub-nav-breadcrumb"
    :style="globalTheme.backgroundColor"
  > 
    <div
      id="desktop-sub-nav-breadcrumb"
      aria-label="subnav"
      role="navigation"
      class="nav-wrap"
    >
      <div container>
        <div grid="row wrap center">
          <div
            column>
            <crumbs
              :crumbs="crumbs"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import Crumbs from './Crumbs.vue'
  export default defineComponent({
    name: 'breadcrumbs',
    components: {
      Crumbs
    },
    props: {
      crumbs: {
        required: true,
        type: Array as () => string[]
      },
    },
  })
</script>
<style scoped>
.member-name {
  text-transform: capitalize;
}
a.disabled {
  text-decoration: none !important;
  cursor: default !important;
  pointer-events: none;
}
</style>
