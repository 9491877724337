<template>
  <div
    role="main"
    :class="overrideClass ? overrideClass : 'rich-text themed'"
    v-html="html">
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      html: {
        type:String,
        required: true
      },
      overrideClass: {
        required: false,
        type: String
      },
      themeColorOverride: {
        required: false,
        type: Array as ()=> Record<string, unknown>[]
      }
    },
    data() {
      return {
        clientID: this.$store.state.globals.clientId.toLowerCase() as string,
        selectedLanguage: this.$route.params.lang as string
      }
    },
    computed: {
      colorOverride(): string | undefined | unknown {
        const colorOverrideObject =
          this.themeColorOverride &&
          this.themeColorOverride.length
            ? this.themeColorOverride[0]
            : {}
        return colorOverrideObject.colorHex as string | undefined
      }
    },
    methods: {
      checkAndSetInternalLinks(): void {
        this.$el.querySelectorAll('a').forEach((link) => {
          // modify relative, internal links to work with all client ID combinations
          if (link.href && link.href && link.href.includes('$SITE_URL')) {
            link.href = link.href.split('%20').join('')
            const splitUrl = link.href.split('$SITE_URL').pop()
            link.href = `/${this.clientID}/${this.selectedLanguage}/${splitUrl}`.replace(/\/\//g, '/')
          } else {
            link.setAttribute('target', '_blank')
            link.setAttribute('aria-describedby', 'leave-page-label')
          }
        })
      },
      colorRichTextLinks(): void {
        const links = this.$el.querySelectorAll('a:not(.button)')
        if (links.length) {
          [...links].map(link => {
            (link as HTMLElement).style.color = (this as any).primaryColor
          })
        }
      },
      colorHeadings(): void {
        const headings = this.$el.querySelectorAll('h1, h2, h3, h4, h5, h6')
        if (headings.length) {
          [...headings].map(link => {
            (link as HTMLElement).style.color = (this as any).colorOverride || (this as any).primaryColor
          })
        }
      },
      colorBlockquotes(): void {
        const blockquotes = this.$el.querySelectorAll('blockquote')
        if (blockquotes.length) {
          [...blockquotes].map(blockquote => {
            (blockquote as HTMLElement).style.borderColor = (this as any).primaryColor
          })
        }
      },
      scrollToInternalHashLinks(): void {
        const links = this.$el.querySelectorAll('a')
        if (links.length) {
          [...links].map(link => {
            if (link.hash) {
              link.addEventListener('click', (e) => {
                if (!link.href) return
                if (link.href.pathname === window.location.pathname || !link.href.pathname){
                  e.preventDefault()
                  const scrollElID = link.hash.substring(1)
                  const scrollEl = document.getElementById(scrollElID)
                  if (scrollEl) {
                    scrollEl.setAttribute('tabindex', '0')
                    scrollEl.focus()
                    scrollEl.removeAttribute('tabindex')
                  }
                }
              })
            } 
          })
        }
      }
    },
    mounted() {
      this.checkAndSetInternalLinks()
      this.colorRichTextLinks()
      this.colorHeadings()
      this.colorBlockquotes()
      this.scrollToInternalHashLinks()
    }
  })
</script>
