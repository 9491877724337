export function navigateToHash(hash: string): void {
  const focusEl: HTMLElement | null = document.querySelector(hash)
  if (!focusEl) return
  focusEl.scrollIntoView()
  if (focusEl.getAttribute('data-focusable') === 'true') {
    focusEl.setAttribute('tabindex', '0')
    focusEl.focus()
    focusEl.removeAttribute('tabindex')
    return
  } else {
    const nextLink = findNextTabStop(focusEl)
    if (!nextLink) return
    nextLink.focus()
  }
  function findNextTabStop(el): HTMLElement | null {
    const universe = el.querySelectorAll(
      'input, button, select, textarea, a[href]'
    )
    const list = Array.prototype.filter.call(universe, function(item) {
      return item.tabIndex >= '0'
    })
    const index = list.indexOf(el)
    return list[index + 1] || list[0]
  }
}

export function trapFocus(element: HTMLElement): void {
  const focusableEls: NodeListOf<HTMLElement> = element.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  )

  if (!focusableEls || !focusableEls.length) return
  const firstFocusableEl: HTMLElement = focusableEls[0]
  const lastFocusableEl: HTMLElement = focusableEls[focusableEls.length - 1]
  const KEYCODE_TAB = 9

  element.addEventListener('keydown', function(e: KeyboardEvent) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

    if (!isTabPressed) {
      return
    }

    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus()
        e.preventDefault()
      }
    } /* tab */ else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus()
        e.preventDefault()
      }
    }
  })
}

export function setExternalLinkAriaLabels(parentEl: Element): void {
  const allExtrnalLinks: NodeListOf<HTMLLinkElement> = parentEl.querySelectorAll('a[target="_blank"]')
  Array.from(allExtrnalLinks).map((link:HTMLLinkElement) => {
    if (!link.hasAttribute('aria-describedby')) {
      link.setAttribute('aria-describedby','leave-page-label')
    }
  })
}

export function chooseTabbedData(
  event: KeyboardEvent,
  catName: string,
  listToFilter: any[],
  keyToFilterOn: string,
  callBackFunction: (catToSet: any) => any|void
): void {
  const totalCatLength: number = listToFilter.length
  const currentCatIndex: number = listToFilter.findIndex(
    cat => cat[keyToFilterOn] === catName
  )
  let nextCatIndex: number|undefined
  switch (event.keyCode) {
  case 37:
    nextCatIndex = currentCatIndex - 1
    if (nextCatIndex === -1) {
      nextCatIndex = totalCatLength - 1 
    }
    break
  case 39:
    nextCatIndex = currentCatIndex + 1
    if (nextCatIndex >= totalCatLength) {
      nextCatIndex = 0
    }
    break
  }
  if (nextCatIndex === undefined) return
  const nextCat: Record<string,unknown> | undefined = listToFilter[nextCatIndex]
  if (nextCat !== undefined) {
    callBackFunction(nextCat)
  }
}
