import Contentstack from 'contentstack'

const api_key = import.meta.env.VITE_CONTENTSTACK_API_KEY || ''
const delivery_token = import.meta.env.VITE_CONTENTSTACK_DELIVERY_TOKEN || ''
const environment_name = import.meta.env.VITE_CONTENTSTACK_ENV_NAME || ''

const Stack = Contentstack.Stack({
  api_key: api_key,
  delivery_token: delivery_token,
  environment: environment_name
})
import { objectKeysToCamelCase } from '@oodletech/oodle-js/js'
import { createLoopableSections } from './loopable.sections'
import {
  ContentStackPagePayload,
  ContentStackEntryPayload,
  ContentStackAllCategoriesPayload
} from '@/types'

export const includeReferenceList: string[] = [
  'sections',
  'sections.sidebar.related_posts',
  'sections.sidebar.theme_color_override',
  'sections.microsite_references.omit_from',
  'sections.microsite_references.appear_only',
  'sections.color_options.theme_color_override',
  'sections.colors.theme_color_override',
  'sections.choice_modal.theme_color_override',
  'sections.featured_content.theme_color_override',
  'sections.chart_column.theme_color_override',
  'sections.featured_posts.choice.category.reference',
  'sections.featured_posts.choice.category.reference.microsite_references.omit_from',
  'sections.featured_posts.choice.category.reference.microsite_references.appear_only',
  'sections.featured_posts.choice.posts.reference',
  'sections.featured_posts.choice.posts.reference.category',
  'sections.featured_posts.choice.posts.reference.microsite_references.omit_from',
  'sections.featured_posts.choice.posts.reference.microsite_references.appear_only'
]

export const getPage = async (
  stackPayLoad: ContentStackPagePayload
): Promise<any | null> => {
  const pageQuery = Stack.ContentType(stackPayLoad.pageType || 'page').Query()

  const siteGlobalsQuery = Stack.ContentType('globals')
    .Query()
    .where('client_id', stackPayLoad.clientID)
    .language(`${stackPayLoad.lang}-us`)

  const restGlobalsQuery = Stack.ContentType('globals')
    .Query()
    .notContainedIn('client_id', [stackPayLoad.clientID])
    .language(`${stackPayLoad.lang}-us`)

  const page = pageQuery
    .language(`${stackPayLoad.lang}-us`)
    .includeReference(stackPayLoad.includeReferenceList || includeReferenceList)
    .referenceNotIn('microsite_references.omit_from', siteGlobalsQuery)
    .referenceNotIn('microsite_references.appear_only', restGlobalsQuery)
    .where('url', stackPayLoad.pageSlug)
    .find()

  let data = await page
    .then(function(result) {
      return result[0][0] ? result[0][0].toJSON() : null
    })
    .catch(() => null)

  if (data) {
    data = objectKeysToCamelCase(data)
    if (stackPayLoad.pageType === 'posts' && !data.postType) {
      data.postType = { postType: 'Article' }
    }
    const page = { ...data }
    page.loopableSections = createLoopableSections(data.sections)
    return page
  } else {
    return null
  }
}

export const getGlobals = async (
  stackPayLoad: ContentStackPagePayload
): Promise<any | null> => {
  const globalsQuery = Stack.ContentType('globals').Query()
  const globals = globalsQuery
    .language(`${stackPayLoad.lang}-us`)
    .where('client_id', stackPayLoad.clientID)
    .includeReference([
      'search_page',
      'nav_theme_color_override',
      'main_nav_items.top_level_nav_item.page_link',
      'main_nav_items.category.links.internal_link.page_link',
      'search_page.microsite_references.omit_from',
      'main_nav_items.top_level_nav_item.page_link.microsite_references.omit_from',
      'main_nav_items.category.links.internal_link.page_link.microsite_references.omit_from',
      'search_page.microsite_references.appear_only',
      'main_nav_items.top_level_nav_item.page_link.microsite_references.appear_only',
      'main_nav_items.category.links.internal_link.page_link.microsite_references.appear_only',
      'footer_nav_items.link_column.column_header_internal_link',
      'footer_nav_items.link_column.links.internal_link.page_link',
      'footer_nav_items.link_column.links.internal_link.page_link.microsite_references.omit_from',
      'footer_nav_items.link_column.links.internal_link.page_link.microsite_references.appear_only'
    ])
    .find()

  const data = await globals
    .then(function(result) {
      return result[0][0] ? result[0][0].toJSON() : null
    })
    .catch(() => null)

  if (data) {
    return objectKeysToCamelCase(data)
  } else {
    return null
  }
}

import * as Utils from '@contentstack/utils'
const renderOption = {
  ['span']: (node, next) => {
    return next(node.children)
  }
}
export const getPostsByCategory = async (
  stackPayLoad: ContentStackEntryPayload
): Promise<{ posts: any[]; count: number } | null> => {
  const query = Stack.ContentType(stackPayLoad.contentTypeUid)
    .Query()
    .language(`${stackPayLoad.lang}-us`)

  const siteGlobalsQuery = Stack.ContentType('globals')
    .Query()
    .where('client_id', stackPayLoad.clientID)
    .language(`${stackPayLoad.lang}-us`)

  const restGlobalsQuery = Stack.ContentType('globals')
    .Query()
    .notContainedIn('client_id', [stackPayLoad.clientID])
    .language(`${stackPayLoad.lang}-us`)

  if (stackPayLoad.referenceFieldPath)
    query.includeReference(stackPayLoad.referenceFieldPath)

  const coreQuery = query
    .includeReference(['sections'])
    .referenceNotIn('microsite_references.omit_from', siteGlobalsQuery)
    .referenceNotIn('microsite_references.appear_only', restGlobalsQuery)
    .limit(stackPayLoad.limit || 100)
    .skip(stackPayLoad.skip || 0)
    .descending('date')
    .includeCount()
    .includeOwner()

  let postsByCat

  if (stackPayLoad.categoryUrl) {
    const catQuery = Stack.ContentType('categories')
      .Query()
      .language(`${stackPayLoad.lang}-us`)
      .where('url', stackPayLoad.categoryUrl)
    postsByCat = coreQuery
      .referenceIn('category', catQuery)
      .toJSON()
      .find()
  } else {
    postsByCat = coreQuery.toJSON().find()
  }

  const data = await postsByCat
    .then(
      result => {
        stackPayLoad.jsonRtePath &&
          Utils.jsonToHTML({
            entry: result,
            paths: stackPayLoad.jsonRtePath,
            renderOption
          })
        return { posts: result[0], count: result[1] }
      },
      () => {
        return null
      }
    )
    .catch(() => null)

  if (data) {
    data.posts = data.posts.map(post => {
      if (!post.post_type || !post.post_type.post_type) {
        post.post_type = { post_type: 'Article' }
      }
      return post
    })
    return objectKeysToCamelCase(data)
  } else {
    return null
  }
}

export const getMicrositeList = async (): Promise<any | null> => {
  const globalsQuery = Stack.ContentType('globals').Query()
  const globals = globalsQuery.toJSON().find()

  const data = await globals
    .then(function(result) {
      return result[0]
    })
    .catch(() => null)

  if (data) {
    return objectKeysToCamelCase(data)
  } else {
    return null
  }
}

export const getCategoryList = async (
  stackPayLoad: ContentStackAllCategoriesPayload
): Promise<any | null> => {
  const categoriesQuery = Stack.ContentType('categories').Query()

  const siteGlobalsQuery = Stack.ContentType('globals')
    .Query()
    .where('client_id', stackPayLoad.clientID)
    .language(`${stackPayLoad.lang}-us`)

  const restGlobalsQuery = Stack.ContentType('globals')
    .Query()
    .notContainedIn('client_id', [stackPayLoad.clientID])
    .language(`${stackPayLoad.lang}-us`)

  const categories = categoriesQuery
    .includeReference(stackPayLoad.includeReferenceList || includeReferenceList)
    .referenceNotIn('microsite_references.omit_from', siteGlobalsQuery)
    .referenceNotIn('microsite_references.appear_only', restGlobalsQuery)
    .toJSON()
    .find()

  const data = await categories
    .then(function(result) {
      return result[0]
    })
    .catch(() => null)

  if (data) {
    return objectKeysToCamelCase(data)
  } else {
    return null
  }
}
