import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/scss/application.scss'
const app = createApp(App)

import { theme } from '@/mixins/Theme'
app.mixin(theme)

import AppSection from '@/components/_global/AppSection.vue'
import AppButtonLink from '@/components/_global/AppButtonLink.vue'
import SvgCaretRight from '@/components/_global/Svg/SvgCaretRight.vue'
import SvgChevronRight from '@/components/_global/Svg/ChevronRight.vue'
import AppInlineSvg from '@/components/_global/AppInlineSvg.vue'
import AppImageSet from '@/components/_global/AppImageSet.vue'
import AppCollapsibleSection from '@/components/_global/AppCollapsibleSection.vue'
import RichText from '@/components/_global/RichText.vue'
import AppImage from '@/components/_global/AppImage.vue'
import LocalizedRouterLink from '@/components/_global/RouterLinkLocale.vue'
import AppLoaderSection from '@/components/_global/AppLoaderSection.vue'

app.component('app-section', AppSection)
app.component('app-button-link', AppButtonLink)
app.component('app-svg-caret-right', SvgCaretRight)
app.component('svg-chevron-right', SvgChevronRight)
app.component('app-inline-svg', AppInlineSvg)
app.component('app-image-set', AppImageSet)
app.component('app-collapsible-section', AppCollapsibleSection)
app.component('rich-text', RichText)
app.component('app-image', AppImage)
app.component('localized-router-link', LocalizedRouterLink)
app.component('app-loader', AppLoaderSection)

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faMinus,
  faPlus,
  faPlay,
  faExternalLinkAlt,
  faSpinner,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'

library.add(faPlus, faMinus, faPlay, faExternalLinkAlt, faSpinner, faChevronUp, faChevronDown)
app.component('font-awesome-icon', FontAwesomeIcon)
dom.watch()

import { addAdobeDigitalData } from '@/ts/adobe-analytics/index'

addAdobeDigitalData()

app.config.warnHandler = () => null
app
  .use(store)
  .use(router)
  .mount('#app')
