import { MainNavigationItem,MainNavigationItemExternal, MainNavigationGroup } from '@/types'
import { paramCase } from 'change-case'

export function mainNavigationFactory(navItems: any[], clientID?: string): (MainNavigationItem|MainNavigationItemExternal|MainNavigationGroup)[] {
  const mainNavigation: (MainNavigationItem|MainNavigationItemExternal|MainNavigationGroup)[] = navItems.map(navItem => {
    if (Object.keys(navItem)[0] === 'topLevelNavItem') {
      return createInternalLink(navItem.topLevelNavItem.pageLink[0], navItem.topLevelNavItem.linkTitle, clientID) as MainNavigationItem
    }
    else if (Object.keys(navItem)[0] === 'topLevelExternalLink') {
      const externalLink = navItem.topLevelExternalLink
      return createExternalLink(externalLink) as MainNavigationItemExternal
    }
    else {
      const groupedLinks = navItem.category.links || []
      return {
        group: paramCase(navItem.category.category),
        label: navItem.category.category,
        secondTier: groupedLinks.map(link => {
          if (link.internalLink) {
            const internalLink = link.internalLink
            return createInternalLink(internalLink.pageLink[0], internalLink.linkTitle, clientID) as MainNavigationItem
          } else {
            const externalLink = link.externalLink
            return createExternalLink(externalLink) as MainNavigationItemExternal
        }
      })
      .filter(link => link.hideLink !== true)
      }as MainNavigationGroup
    }
  })
  .filter(navItem => navItem.hideLink !== true)

  
  return mainNavigation
}

import { filterPagetypeByReferences } from '@/mixins/FeaturedPostsModuleState'

export function createInternalLink(pageLink, title: string, clientID?: string):MainNavigationItem|undefined {
  
  if (!pageLink) return
  let urlModifer = ''
  if (pageLink.contentTypeUid === 'posts') {
    urlModifer = '/post'
  }
  if (pageLink.contentTypeUid === 'categories') {
    urlModifer = '/category'
  }

  let hideLink = !pageLink.url

  if (clientID && pageLink.micrositeReferences) {
    hideLink = !filterPagetypeByReferences(pageLink.micrositeReferences, clientID)
  }

  return {
    label:title,
    url: removeSlash(urlModifer + pageLink.url),
    column: pageLink.column || null as number|null,
    hideLink: hideLink
   } as MainNavigationItem
}

function removeSlash(url: string): string {
  return url ? url.replace(/^\/|\/$/g, '') : ''
}

export function createExternalLink(externalLink):MainNavigationItemExternal {
  return externalLink && externalLink.link
    ? {
        label: externalLink.link.title as string,
        externalUrl: externalLink.link.href as string
      }
    : { label: '' as string, externalUrl: '' as string }
}


