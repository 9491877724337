<template>
  <Glossary
    :section="section"
    :search-query="searchQuery"
    search-glossary />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'

  import { TermProps } from './Glossary.vue'

  export interface SearchGlossaryProps extends Boilerplate {
    terms?: TermProps[]
    searchLabel?: string
    noResultsMessage?: string
  }

  import Glossary from './Glossary.vue'
  import { createInternalLink } from '@/ts/factories/main-navigation.factory'
  import { filterPagetypeByReferences } from '@/mixins/FeaturedPostsModuleState'

  export default defineComponent({
    name: 'search-glossary',
    components: { Glossary },
    props: {
      section: {
        type: Object as () => SearchGlossaryProps,
        required: true,
      },
    },
    data() {
      return {
        searchQuery: this.$route.query.s,
        labels: this.$store.state.globals.globalLabels,
      }
    },
    async created() {
      if (this.section.terms && this.section.terms.length) {
        this.section.terms.map((term) => {
          if (term.associatedPages && term.associatedPages.length) {
            const filteredAssociatedPages = term.associatedPages
              .map((searchPage) => {
                searchPage.internalLink = createInternalLink(
                  searchPage,
                  searchPage.title,
                  this.$route.params.clientID as string
                )

                if (searchPage.contentTypeUid === 'posts') {
                  searchPage.pageType = this.labels.post
                } else if (searchPage.contentTypeUid === 'categories') {
                  searchPage.pageLabel = this.labels.category
                  searchPage.postType = { postType: 'category' }
                } else {
                  searchPage.pageLabel = this.labels.page
                  searchPage.postType = { postType: 'page' }
                }

                if (
                  searchPage.contentTypeUid === 'posts' &&
                  (!searchPage.postType || !searchPage.postType.postType)
                ) {
                  searchPage.postType = { postType: 'Article' }
                }

                return searchPage
              })
              .filter((searchPage) =>
                filterPagetypeByReferences(
                  searchPage.micrositeReferences,
                  this.$route.params.clientID as string
                )
              )
            term.associatedPages = filteredAssociatedPages
            return term
          }
        })
      }
    },
  })
</script>
