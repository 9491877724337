<template>
  <div
    v-if="post.link"
    class="large-card"
    :class="{ 'no-image': !post.featuredImage }"
  >
    <app-image
      v-if="post.featuredImage"
      aria-hidden
      :image="post.featuredImage" />
    <div class="content">
      <p
        v-if="post.postType && post.postType.postType"
        class="post-type-label"
        :style="[globalTheme.colorSecondary]"
      >
        {{ post.postType.postType }}
      </p>
      <p class="title _mbxs">{{ post.title }}</p>
      <p class="blurb">
        {{ post.blurb }}
      </p>
      <localized-router-link
        v-if="post.link"
        class="btn cta"
        :to="post.link.href"
        :style="globalTheme.button"
      >
        {{ post.link.title }}
      </localized-router-link>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    ContentStackPost,
    PostType
  } from '@/types'

  export interface LargeCardProps {
    postType: PostType;
    blurb?: string;
    title?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  import { setPostLinkCardBlurb } from '@/ts/utility.methods'

  export function largeCardFactory(
    post: ContentStackPost,
    readMoreLabel: string
  ): LargeCardProps {
    return {
      featuredImage: post.featuredThumbnail || post.featuredImage,
      postType: post.postType,
      title: post.title,
      blurb: setPostLinkCardBlurb(post),
      link: {
        title: readMoreLabel,
        href: `/post${post.url}`
      }
    }
  }

  export default defineComponent({
    name: 'LargeCard',
    props: {
      post: {
        type: Object as () => LargeCardProps,
        required: false
      }
    }
  })
</script>
