<template>
  <localized-router-link
    v-if="post.link"
    class="category-card"
    :to="post.link.href"
    :class="{ 'no-image': !post.featuredImage }"
    :style="globalTheme.borderColor"
  >
    <div
      class="background"
      :style="globalTheme.backgroundColor"></div>
    <div class="content">
      <post-type-icon
        v-if="post.postType && post.postType.postType"
        :post-type="post.postType"
        :style="globalTheme.fillTextSoft"/>
      <p
        v-if="post.postType && post.postType.postType"
        class="post-type-label"
        :style="globalTheme.colorSoft"
      >
        {{ post.postType.postType }}
      </p>
      <p
        v-if="post.title"
        :style="globalTheme.colorSoft"
        class="title _mbxs">{{ post.title }}</p>
      <p
        v-if="post.blurb"
        class="blurb">
        {{ post.blurb }}
      </p>
      <p
        v-if="post.link.title"
        class="read-more"
        :style="globalTheme.colorText">
        {{post.link.title}}
      </p>
    </div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    ContentStackPost,
    PostType
  } from '@/types'

  export interface CategoryCardProps {
    postType?: PostType;
    blurb?: string;
    title?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  import { setPostLinkCardBlurb } from '@/ts/utility.methods'

  export function categoryCardFactory(
    post: ContentStackPost,
    readMoreLabel: string
  ): CategoryCardProps {
    return {
      postType: post.postType,
      title: post.title,
      blurb: setPostLinkCardBlurb(post),
      link: {
        title: readMoreLabel,
        href: `/post${post.url}`
      }
    }
  }

  export function miniCalloutCardFactory(
    post: ContentStackPost,
    readMoreLabel: string
  ): CategoryCardProps {
    return {
      title: post.title,
      blurb: setPostLinkCardBlurb(post),
      link: {
        title: readMoreLabel,
        href: `/post${post.url}`
      }
    }
  }

  import PostTypeIcon from './PostTypeIcon.vue'

  export default defineComponent({
    name: 'CategoryCard',
    components: { PostTypeIcon },
    props: {
      post: {
        type: Object as () => CategoryCardProps,
        required: false
      }
    }
  })
</script>
