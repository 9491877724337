<template>
  <div class="category-selector">
    <label
      for="category-selector"
      class="visuallyhidden"
    >select a category</label
    >
    <select
      v-model="selectedCategory" 
      name="category-selector">
      <option
        v-for="cat in allCategories"
        :key="cat.title"
        :value="cat">
        {{ cat.title }}
      </option>
    </select>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackCategoryPage } from '@/types'

  export default defineComponent({
    props: {
      currentCategory: {
        type: Object as () => ContentStackCategoryPage,
        required: true,
      },
    },
    data() {
      return {
        allCategories: this.$store.state
          .allCategories.sort((a, b) => a.title.localeCompare(b.title)) as ContentStackCategoryPage[],
        selectedCategory: this.$store.state
          .allCategories.find(cat => cat.uid === this.currentCategory.uid),
      }
    },
    watch: {
      selectedCategory: {
        handler(newCat) {
          this.$emit('newCategorySelected', newCat)
        },
        deep: true
      }
    }
  })
</script>
