<template>
  <div
    v-if="post.link"
    class="related-post"
    :class="{ 'no-image': !post.featuredImage }"
  >
    <div
      class="strip _mbxs"
      :style="globalTheme.backgroundColor" />
    <app-image
      v-if="post.featuredImage"
      aria-hidden
      :image="post.featuredImage" />
    <div class="content">
      <p
        class="label"
        :style="globalTheme.color">{{relatedArticleLabel || 'Related Article'}}</p>
      <p
        class="title _mbxs"
        :style="globalTheme.color">{{ post.title }}</p>
      <localized-router-link
        v-if="post.link"
        class="btn cta"
        :to="post.link.href"
        :style="globalTheme.button"
      >
        {{ post.link.title }}
      </localized-router-link>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    LargeCardProps,
  } from '@/components/sections/FeaturedPosts/LargeCard.vue'

  export default defineComponent({
    name: 'RelatedPost',
    props: {
      post: {
        type: Object as () => LargeCardProps,
        required: false
      },
      themeColorOverride: {
        required: false,
        type: Array as ()=> Record<string, unknown>[]
      }
    },
    data() {
      return {
        relatedArticleLabel: this.$store.state.globals.globalLabels.relatedArticle
      }
    },
    computed: {
      colorOverride(): string | undefined | unknown {

        const colorOverrideObject =
          this.themeColorOverride &&
          this.themeColorOverride.length
            ? this.themeColorOverride[0]
            : {
              colorHex: (this as any).secondaryColor
            }
        return colorOverrideObject.colorHex as string | undefined
      }
    },
  })
</script>
