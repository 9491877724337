<template>
  <div
    :style="setBackgroundColor(section)"
    class="hero-with-content member-demo-module">
    <img
      v-if="imageMobilePath"
      :alt="section.imageAltMobile || section.heroAlt"
      :src="imageMobilePath"
      class="mobile until-tablet hero-image"
    />
    <img
      v-if="imageDesktopPath"
      :alt="section.imageAltDesktop || section.heroAlt"
      :src="imageDesktopPath"
      class="tablet-up hero-image"
    />
    <section
      v-if="section.heroTitle || section.content"
      role="region"
      :aria-label="`Title: ${section.heroTitle || section.content}`"
      class="content">
      <div container>
        <h1
          v-if="section.heroTitle"
          class="_text-center h1"
          :style="globalTheme.color"
        >
          {{ section.heroTitle }}
        </h1>
        <rich-text
          v-if="section.content"
          :html="section.content"
          class="not-themed"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
  import { Hero } from '@/types'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'hero',
    props: {
      section: {
        type: Object as () => Hero,
        required: true
      }
    },
    data() {
      return {
        imageDesktopPath: this.section.imageDesktop
          ? this.section.imageDesktop.url
          : null
      }
    },
    computed: {
      imageMobilePath(): string | null {
        const imageMobilePath = this.section.imageMobile
          ? this.section.imageMobile.url
          : null
        return imageMobilePath || this.imageDesktopPath
      }
    },
    methods: {
      setBackgroundColor(section): { backgroundColor: string } {
        return {
          backgroundColor:
            section.colorOptions && section.colorOptions.backgroundAsOffwhite
              ? '#F8F8F8'
              : '#fff'
        }
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
