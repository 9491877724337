<template>
  <svg
    v-if="isAetna"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="chevron-right"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
    class="svg-inline--fa fa-chevron-right"><path
      fill="currentColor"
      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
      class=""></path></svg>
  <svg
    v-else
    aria-hidden="true"
    aria-label="caret right"
    class="svg-inline--fa fa-caret-right fa-w-6"
    data-fa-i2svg=""
    data-icon="caret-right"
    data-prefix="fas"
    role="presentation"
    viewBox="0 0 192 512"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
    fill="currentColor"
  ></path></svg>
</template>
