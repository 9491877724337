<template>
  <img :src="setImage()" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'image-set',
    props: {
      imageSet: {
        required: true,
        type: Object
      }
    },
    methods: {
      setImage(): string {
        return window.innerWidth < 768 && this.imageSet.mobile ? this.imageSet.mobile : this.imageSet.desktop
      }
    },
    mounted(): void {
      if (!this.setImage()) return
    }
  })
</script>
<style>
  img.full-width {
    width: 100%;
  }
</style>
