<template>
  <section
    class="member-demo-module featured-copy icon-bullet-section single"
    :class="{ 'reverse-order': section.mirrorLayout }"
  >
    <div container>
      <div grid="row wrap justify-center center">
        <div
          v-if="section.image"
          class="featured-copy-image-wrap"
          column="xs-12 s-12 m-6 l-6"
        >
          <div grid="row wrap justify-center">
            <div column="xs12 s-12 m-10">
              <app-image
                :image="section.image"
                :alt="section.imageAlt"
                class="full-width"
              />
            </div>
          </div>
        </div>
        <div
          class="featured-copy-wrap"
          :column="smallFeatureColumnCount">
          <div class="feature-wrap">
            <div class="content-wrap">
              <h2
                v-if="smallFeature.sectionTitle"
                :style="globalTheme.color"
                class="section-header h2"
              >
                {{ smallFeature.sectionTitle }}
              </h2>
              <rich-text
                v-if="smallFeature.content"
                class="content"
                :html="smallFeature.content"
              ></rich-text>
              <div
                v-for="(bulletPoint, i ) in smallFeature.iconBulletPoints"
                :key="i"
                class="bullet-point">
                <app-inline-svg
                  v-if="bulletPoint.svgIcon && bulletPoint.svgIcon.url"
                  class="point-svg"
                  :src="bulletPoint.svgIcon.url"
                  :style="[globalTheme.color, globalTheme.fill]"
                />
                <h4
                  v-if="bulletPoint.point"
                  :style="globalTheme.color">{{bulletPoint.point}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    Boilerplate
  } from '@/types'

  interface BulletPoint {
    point?: string,
    svgIcon?: ContentStackFile
  }

  interface IndividualFeature {
    sectionTitle?: string;
    content?: string;
    iconBulletPoints?: BulletPoint[]
  }

  export interface IconBulletSectionProps extends Boilerplate {
    featuredContent: IndividualFeature;
    image?: ContentStackFile;
    imageAlt?: string;
    mirrorLayout: boolean;
  }

  export default defineComponent({
    name: 'IconBulletSection',
    props: {
      section: {
        required: true,
        type: Object as () => IconBulletSectionProps
      }
    },
    data() {
      return {
        smallFeature: this.section.featuredContent
      }
    },
    computed: {
      smallFeatureColumnCount() {
        return this.section.image && this.section.image.url
          ? 'xs-12 s-12 m-6'
          : 'xs-12 s-12 m-6 l-8'
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
<style lang="scss">
.icon-bullet-section .bullet-point {
  display:flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 2rem;
}
.icon-bullet-section .point-svg {
  flex: 0 1 50px;
  min-width:50px;
  max-width:50px;
  height:50px;
  margin-right: 1rem;
}
</style>
