import { Locale } from '@/types'

const browserPreferredLanguage: string =
  window.navigator['userLanguage'] || window.navigator.language

export const localeOptions: Locale[] = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'es',
    label: 'Español'
  }
]

export const locales: string[] = localeOptions.map(locale => locale.value)

const preferredLocaleParam: string | undefined = locales.find(locale =>
  browserPreferredLanguage.includes(locale)
)

export const defaultLocale: string = preferredLocaleParam
  ? preferredLocaleParam
  : 'en'
