<template>
  <section
    class="member-demo-module featured-copy single"
    :class="{ 'reverse-order': section.mirrorLayout }"
  >
    <div container>
      <div grid="row wrap justify-center center">
        <div
          v-if="section.image"
          class="featured-copy-image-wrap"
          column="xs-12 s-12 m-6 l-6"
        >
          <div grid="row wrap justify-center">
            <div column="xs12 s-12 m-10">
              <app-image
                :image="section.image"
                :alt="section.imageAlt"
                class="full-width"
              />
            </div>
          </div>
        </div>
        <div
          class="featured-copy-wrap"
          :column="smallFeatureColumnCount">
          <div class="feature-wrap">
            <div class="content-wrap">
              <app-inline-svg
                v-if="smallFeature.icon && smallFeature.icon.url"
                class="title-svg"
                :src="smallFeature.icon.url"
                :style="[globalTheme.color, globalTheme.fill]"
              />
              <h2
                v-if="smallFeature.sectionTitle"
                :style="globalTheme.color"
                class="section-header h2"
              >
                {{ smallFeature.sectionTitle }}
              </h2>
              <rich-text
                v-if="smallFeature.content"
                class="content"
                :html="smallFeature.content"
              ></rich-text>
              <resource
                v-if="resourceFactory(smallFeature)"
                :resource="resourceFactory(smallFeature)"
                :background-as-offwhite="section.colorOptions.backgroundAsOffwhite"
              />
            </div>
            <app-button-link
              :button="smallFeature.buttonLink"
              :style="globalTheme.button"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ButtonLink,
    ContentStackFile,
    Resource as ResourceInterface,
    Boilerplate
  } from '@/types'
  import Resource from '@/components/sections/Shared/Resource.vue'

  interface IndividualFeature {
    icon?: ContentStackFile;
    sectionTitle?: string;
    content?: string;
    resource?: ResourceInterface;
    resourceLink?: string;
    resourceTitle?: string;
    svgIcon?: ContentStackFile;
    buttonLink?: ButtonLink;
  }

  export interface SingleSmallFeatureProps extends Boilerplate {
    featuredContent: IndividualFeature;
    image?: ContentStackFile;
    imageAlt?: string;
    mirrorLayout: boolean;
  }
  import { resourceFactory } from '@/ts/factories/resource.factory'

  export default defineComponent({
    name: 'SmallFeatureSingle',
    components: {
      Resource
    },
    props: {
      section: {
        required: true,
        type: Object as () => SingleSmallFeatureProps
      }
    },
    data() {
      return {
        smallFeature: this.section.featuredContent
      }
    },
    computed: {
      smallFeatureColumnCount() {
        return this.section.image && this.section.image.url
          ? 'xs-12 s-12 m-6'
          : 'xs-12 s-12 m-6 l-8'
      }
    },
    methods: {
      resourceFactory
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
<style scoped>
.btn {
  align-self: flex-start;
}
</style>
