<template>
  <localized-router-link
    v-if="post.link"
    class="article-card"
    :to="post.link.href"
    :style="globalTheme.color"
    :class="{ 'no-image': !post.featuredImage }"
  >
    <app-image
      v-if="post.featuredImage"
      aria-hidden="true"
      :image="post.featuredImage" />
    <div
      class="strip"
      :style="globalTheme.backgroundColor" />
    <div class="content" role="region" aria-label="Article">
      <p
        v-if="post.postType && post.postType.postType && showPostType"
        class="post-type-label"
        :style="[globalTheme.colorSecondary]"
      >
        {{ post.postType.postType }}
      </p>
      <p class="title _mbxs">{{ post.title }}</p>
      <p
        class="read-more"
        :style="globalTheme.colorText">
        {{ post.link.title }}
      </p>
    </div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    ContentStackPost,
    PostType
  } from '@/types'

  export interface ArticleCardProps {
    postType: PostType;
    blurb?: string;
    title?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  import { setPostLinkCardBlurb } from '@/ts/utility.methods'

  export function articleCardFactory(post: ContentStackPost): ArticleCardProps {
    return {
      blurb: setPostLinkCardBlurb(post),
      title: post.title,
      postType: post.postType,
      featuredImage: post.featuredThumbnail || post.featuredImage,
      link: {
        title: setPostLinkCardBlurb(post),
        href: `/post${post.url}`
      }
    }
  }

  export default defineComponent({
    name: 'ArticleCard',
    props: {
      showPostType: {
        type: Boolean,
        default: false
      },
      post: {
        type: Object as () => ArticleCardProps,
        required: false
      }
    }
  })
</script>
