<template>
  <nav
    aria-label="breadcrumb navigation"
    class="breadcrumbs">
    <ul>
      <template v-for="(crumb, i) in crumbs">
        <li
          v-if="i === 0"
          :key="`0_${i}`">
          <localized-router-link
            to="/"
            style="text-transform:capitalize;">
            {{homepageLabel}}
          </localized-router-link>
        </li>
      </template>
      <li
        v-for="(crumb, j) in postCateogryCrumbs"
        :key="j">
        <localized-router-link
          :to="`/category${crumb.url}`"
          style="text-transform:capitalize;"
        >{{ crumb.title }}</localized-router-link
        >
      </li>
      <template v-for="(crumb, i) in crumbs">
        <li
          v-if="i === crumbs.length - 1 && i !== 1"
          :key="`i_${i}`"
          class="active"
          style="cursor:default"
        >
          <router-link
            :to="crumb"
            aria-current="page"
            style="text-transform:capitalize;"
          >
            {{
              currentPageTitle ||
                crumb
                  .replace("/", "")
                  .replace(/-/g, " ")
                  .split("?")[0]
            }}
          </router-link>
        </li>
        <li
          v-else-if="crumbCheck(crumb, i)"
          :key="`j_${i}`">
          <localized-router-link
            :to="getUrlTilCrumb(i)"
            style="text-transform:capitalize;"
          >{{
            crumb
              .replace("/", "")
              .replace(/-/g, " ")
              .split("?")[0]
          }}</localized-router-link
          >
        </li>
      </template>
    </ul>
  </nav>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import { filterPagetypeByReferences } from '@/mixins/FeaturedPostsModuleState'
  export default defineComponent({
    props: {
      crumbs: {
        required: true,
        type: Array as () => string[]
      }
    },
    computed: {
      ...mapGetters([
        'currentPostCategories',
      ]),
      currentPage(): any | undefined {
        return this.$store.state.page
      },
      currentPageTitle(): string | undefined {
        if (!this.currentPage) return undefined
        return this.currentPage.pageTitle || this.currentPage.title
      },
      postCateogryCrumbs(): { title: string; url: string }[] {
        if (!this.currentPostCategories) return []
        const clientID = this.$route.params.clientID as string || ''
        const cats = this.currentPostCategories.filter(cat => {
          if (cat.micrositeReferences) {
            return filterPagetypeByReferences(cat.micrositeReferences, clientID)
          }
          return true
        })
          .map(cat => {
            return {
              url: cat.url,
              title: cat.title
            }
          })
        return cats.filter(cat => (!!cat.url && !!cat.title))
      },
      homepageLabel(): string {
        return this.$route.params.lang === 'es' ? 'página principal' : 'home'
      }
    },
    methods: {
      getUrlTilCrumb(i) {
        const crumbs = [...this.crumbs]
        crumbs.length = i + 1
        crumbs.shift()
        crumbs.shift()
        crumbs.shift()
        const route = crumbs.join('')
        return route
      },
      crumbCheck(crumb, i) {
        return i > 2 && crumb !== '/post' && crumb !== '/category'
      }
    }
  })
</script>
