<template>
  <select
    v-model.trim="currentDomain"
    class="_mlxs"
  >
    <option
      selected
      disabled
      value="">Pick a Microsite</option>
    <option
      v-for="option in options"
      :key="option"
      :value="option"
    >{{ option }}</option>
  </select>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { getMicrositeList } from '@/ts/contentstack'
  export default defineComponent({
    data() {
      return {
        options: [] as any[],
        currentDomain: this.$route.params.clientID ? this.$route.params.clientID: ''
      }
    },
    watch: {
      currentDomain(val) {
        this.changeMicrositeAndReloadApp(val)
      }
    },
    methods: {
      changeMicrositeAndReloadApp(option: string): void {
        this.$router.replace({
          name: this.$route.name as string || undefined,
          params: {
            ...this.$route.params,
            clientID: option
          }
        })
          .then(() => {
            this.$router.go(0)
          })
      }
    },
    async mounted() {
      const micrositeList = Object.values(await getMicrositeList()) as any[]
      this.options = micrositeList.map(microsite => microsite.clientId).sort((a, b) => a.localeCompare(b))
    }
  })
</script>
<style>
.domain-selector {
  z-index: 1;
}
</style>
