<template>
  <localized-router-link
    v-if="post.link"
    class="archive-card"
    :to="post.link.href"
    :style="globalTheme.color"
    :class="{ 'no-image': !post.featuredImage }"
  >
    <post-type-icon
      :post-type="post.postType"
      :style="[globalTheme.fillWhite, globalTheme.backgroundColor]"/>
    <app-image
      v-if="post.featuredImage"
      :image="post.featuredImage"
      aria-hidden="true" />
    <div class="content">
      <p class="title">{{ post.link.title }}</p>
    </div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    PostType,
    ContentStackPost
  } from '@/types'

  export interface ArchiveCardProps {
    postType: PostType;
    blurb?: string;
    title?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  export function archiveCardFactory(post: ContentStackPost, readMoreLabel = 'Read more'): ArchiveCardProps {
    return {
      postType: post.postType,
      featuredImage: post.featuredThumbnail || post.featuredImage,
      link: {
        title: post.title || readMoreLabel,
        href: `/post${post.url}`
      }
    }
  }

  import PostTypeIcon from './PostTypeIcon.vue'

  export default defineComponent({
    name: 'ArchiveCard',
    components: { PostTypeIcon },
    props: {
      post: {
        type: Object as () => ArchiveCardProps,
        required: false
      }
    }
  })
</script>
