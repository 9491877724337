<template>
  <section
    class="member-demo-module icon-text-wide-section "
  >
    <div container>
      <div grid="row wrap justify-center center">
        <div column="xs-12 s-12 m-2">
          <app-inline-svg
            v-if="section.icon && section.icon.url"
            class="title-svg"
            :src="section.icon.url"
            :style="[globalTheme.color, globalTheme.fill]"
          />
        </div>
        <div
          class="content"
          column="xs-12 s-12 m-10">
          <h2
            v-if="section.sectionTitle"
            :style="globalTheme.color"
            class="h2"
          >
            {{ section.sectionTitle }}
          </h2>
          <rich-text
            v-if="section.content"
            class="content"
            :html="section.content"
          ></rich-text>
          <app-button-link
            class="_mts"
            :button="section.buttonLink"
            :style="globalTheme.button"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    Boilerplate,
    ButtonLink
  } from '@/types'

  interface IconWithTextProps extends Boilerplate {
    icon?: ContentStackFile;
    sectionTitle?: string;
    buttonLink?: ButtonLink;
  }

  export default defineComponent({
    name: 'IconWithText',
    props: {
      section: {
        required: true,
        type: Object as () => IconWithTextProps
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
<style scoped>
.btn {
  align-self: flex-start;
}
</style>
