<template>
  <div class="intro-content">
    <h2
      v-if="section.title"
      :style="globalTheme.color"
      class="section-header h2">{{section.title}}</h2>
    <div
      v-if="section.content"
      class="copy-wrap">
      <rich-text :html="section.content"></rich-text>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { SmallCallouts } from '@/types'
  export default defineComponent({
    name: 'small-callouts-title',
    props: {
      section: {
        required: true,
        type: Object as () => SmallCallouts
      }
    }
  })
</script>
