<template>
  <localized-router-link
    v-if="post.link"
    class="featured-card"
    :to="post.link.href"
    :style="globalTheme.color"
    :class="{ 'no-image': !post.featuredImage }"
  >
    <post-type-icon
      :post-type="post.postType"
      :style="[globalTheme.fillWhite, globalTheme.backgroundColor]"/>
    <app-image
      v-if="post.featuredImage"
      aria-hidden
      :image="post.featuredImage" />
    <div class="content _ps">
      <p class="title">{{ post.title }}</p>
      <p
        class="read-more"
        :style="globalTheme.colorText">
        {{ post.link.title }}
      </p>
    </div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    PostType,
    ContentStackPost
  } from '@/types'

  export interface FeaturedCardProps {
    postType: PostType;
    blurb?: string;
    title?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  import { setPostLinkCardBlurb } from '@/ts/utility.methods'

  export function featuredCardFactory(post: ContentStackPost, readMoreLabel = 'Read more'): FeaturedCardProps {
    return {
      postType: post.postType,
      blurb: setPostLinkCardBlurb(post),
      title: post.title,
      featuredImage: post.featuredThumbnail || post.featuredImage,
      link: {
        title: readMoreLabel,
        href: `/post${post.url}`
      }
    }
  }

  import PostTypeIcon from './PostTypeIcon.vue'

  export default defineComponent({
    name: 'FeaturedCard',
    components: { PostTypeIcon },
    props: {
      post: {
        type: Object as () => FeaturedCardProps,
        required: false
      }
    }
  })
</script>
